// Import the plugins
import Uppy from "@uppy/core";
import Form from "@uppy/form";
import XHRUpload from "@uppy/xhr-upload";
import Dashboard from "@uppy/dashboard";
import Webcam from "@uppy/webcam";
import ScreenCapture from "@uppy/screen-capture";
import AwsS3 from "@uppy/aws-s3";
import "@uppy/core/dist/style.css";
import "@uppy/dashboard/dist/style.css";

let urlString = window.location.search;
let paramString = urlString.split('?')[1];
let queryString = new URLSearchParams(paramString);

if (!queryString.has('dest')) {

  console.log('Please enter valid destination code');
  let subtext = `You have entered an invalid url`

  document.getElementById("subtext").innerHTML = subtext;

} else {
  
  let subtext = `<i class="fas fa-lock" style="color: #d6cdca; font-size:24px;"></i>
      Secure Upload`

  document.getElementById("subtext").innerHTML = subtext;

  let s3_folder = queryString.get('dest');

  const uppy = new Uppy()
  	.use(Dashboard, {
  		inline: true,
  		target: "#drag-drop-area",
  		height: 470,
  		showProgressDetails: true,
  		metaFields: [
  			{ id: "name", name: "Name", placeholder: "file name" },
  			{ id: "caption", name: "Caption", placeholder: "add description" },
  		],
  	})
  	.use(ScreenCapture, {
  		target: Dashboard,
  	})
  	.use(Form, {
  		target: Dashboard,
  	})
  	.use(AwsS3, {
      allowedMetaFields: ["folder"],
  		companionUrl: "https://companion.healthjump.com/",
  	});

  uppy.setMeta({
    folder: s3_folder
  })


  uppy.on("complete", (result) => {
  	console.log(
  		"Upload complete! We’ve uploaded these files:",
  		result.successful
  	);
  });
}
